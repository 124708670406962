<template>
  <div :class="`footer-legal __${_for}`">
    <a class="footer-legal-item" href="https://www.rtalabel.org/" target="_blank" name="RTA" aria-label="RTA"><iconic name="mf_rta" /></a>
    <a class="footer-legal-item" href="https://www.asacp.org/" target="_blank" name="ASACP" aria-label="RTA"><iconic name="mf_asacp" /></a>
    <a class="footer-legal-item" href="https://www.fosi.org/icra" target="_blank" name="ICRA" aria-label="RTA"><iconic name="mf_icra" /></a>
  </div>
</template>

<script>
export default {
  props: ["_for"],
};
</script>

<style lang="scss">
$color: $primary-color;
.footer-legal {
  display: flex;
  margin: $mpadding/2 0 0 0;
  gap: $mpadding;
  &.__login {
    margin: $mpadding 0 0 0;
    justify-content: center;
  }
  &-item {
    cursor: pointer;
    opacity: 0.8;
    .iconic svg {
      width: auto;
      height: 0.9em;
      path,
      polygon {
        fill: $color;
        stroke: $color;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
  @include screen_desktop() {
    justify-content: flex-end;
  }
}
</style>
